import { h } from "@stencil/core";
import { FloatingTemplateIndicator } from "./template-indicator";
import { BaselineElement } from "./base-elements";
function getInfoPositionClass(infoposition, size) {
    if (infoposition) {
        return `info-position-${infoposition}`;
    }
    return `info-position-${size === "m" ? "center" : "top"}`;
}
export const InputContainer = (props, content) => {
    var _a;
    const infoPositionClass = {};
    infoPositionClass[getInfoPositionClass(props.infoPosition, props.size)] = true;
    return (h("div", { class: Object.assign({ "form-element-input-container": true }, infoPositionClass), slot: props.slot }, h("div", Object.assign({ class: {
            container: true,
            active: props.active,
            "size-s": props.size === "s",
            "size-m": props.size === "m",
            error: props.error,
            disabled: props.disabled,
            readonly: props.readonly,
        }, title: (_a = props.title) !== null && _a !== void 0 ? _a : "" }, props.custom), h(FloatingTemplateIndicator, Object.assign({}, props.templateIndicatorConfig)), content)));
};
export function getInputContainerBaselineFontSize(hasLabel, containerSize, containsLabeledChildren = false) {
    const spaceBetweenBaselineAndText = 5;
    const labelSpace = 32 + spaceBetweenBaselineAndText;
    const containerHeight = 40;
    const innerContainerSpace = containerHeight * 0.75;
    const result = innerContainerSpace + (hasLabel ? labelSpace : 0) + (containsLabeledChildren ? labelSpace : 0);
    if (containerSize === "s") {
        return result;
    }
    const containerHeightDifference = 8;
    return result + containerHeightDifference / 2;
}
export const LabeledInputContainer = (props, content) => {
    var _a;
    return (h("div", { class: "labeled-input-container",
        // container for keeping the baseline
        style: Object.assign({ display: "flex" }, props.additionalCssStyles), slot: props.slot }, h(BaselineElement, { fontSize: getInputContainerBaselineFontSize(!!props.labelProperties.label, props.containerProperties.size, (_a = props.containerProperties.containsLabeledChildren) !== null && _a !== void 0 ? _a : false) }), h("div", { style: {
            flex: "1",
        } }, h("dx-input-label", Object.assign({}, props.labelProperties), (props.containerProperties.renderInfoSlot == undefined ||
        props.containerProperties.renderInfoSlot) && (h("slot", { slot: "info", name: "info" }))), h(InputContainer, Object.assign({}, props.containerProperties), content))));
};
